function trataErroFormNewsletter(errorMap) {
  for (const idInput in errorMap) {
    const nomeCampo = getNomeCampo(idInput);
    const mensagemErro = getMensagemErro(errorMap[idInput]);
    const mensagemTratada =
      'erro:campo-' + trataTextoDataLayer(nomeCampo) + '-' + trataTextoDataLayer(mensagemErro);
    dataLayerCadastroNewsletter(mensagemTratada);
  }
}

function getMensagemErro(mensagem) {
  const tempElement = document.createElement('textarea');
  tempElement.innerHTML = mensagem;
  return tempElement.value;
}

function getNomeCampo(idInput) {
  return idInput.replace(/(name|email|cpf)News/g, (match, p1) => {
    if (p1 === 'name') {
      return 'nome';
    } else {
      return p1;
    }
  });
}
